import ENV from '@environments'
import axios from 'axios'
import { setItem, getItem, removeItem } from '@utils'
import { ShowNotification, NotificationType } from '@utils'

const TOKEN = 'TOKENVIDEOCONFERENCIA'
const TOKENACFAZ = 'TOKENACFAZ'

const paramsBasic = {
  grant_type: ENV.TOKEN.grant_type_basic,
  provision_key: ENV.TOKEN.password,
  authenticated_userid: ENV.TOKEN.username,
  client_id: ENV.TOKEN.clientId,
  client_secret: ENV.TOKEN.clientSecret
}

const api = axios.create({
  baseURL: ENV?.BASE_URL,
  mode: 'no-cors'
})

api.interceptors.request.use(
  async (request) => {
      const response = await gerarToken()

      request.headers = {
        'Content-Type': 'application/json',
        ...request.headers,
        usuario_so: getItem('osUser'),
        mac: getItem('mac'),
        ip: getItem('IP'),
        idusuario: getItem('IDUSUARIO'),
        maquina: getItem('MAQUINA'),
        usuario: getItem('USUARIO'),
        Authorization: 'Bearer ' + response?.access_token  
      }

    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    ShowNotification(NotificationType.Error, error?.response?.data?.message || error?.message)
    return Promise.reject(error)
  }
)

const gerarToken = async () => {
  const token = getToken()
  const segundosExpirar = new Date().getTime() / 1000;
  const tokenExpirou = segundosExpirar >= token?.dateExpires
  if(!token || tokenExpirou){
    removeToken()
    const response = await axios.post(`${ENV?.BASE_URL}/acfaz/login/oauth2/token`, paramsBasic, {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    setToken(response?.data)
    return response.data
  }

  return token
}

const setToken = (token) => {
  setItem(TOKEN, JSON.stringify(token))
}

const getToken = () => {
  try {
    const userType = getItem('USERTYPE')
    if(userType === 'agr'){
      return getTokenAgr()
    }else{
      return getTokenCliente()
    }
  } catch (error) {
  }
}

const getTokenCliente = () => {
  return JSON.parse(getItem(TOKEN))
}

const getTokenAgr = () => {
  return JSON.parse(getItem(TOKENACFAZ))
}


const removeToken = () => {
  removeItem(TOKEN)
}

export { api }
