import { api } from "../api";
const defaultRouteName = "/acfaz/pedido/solicitacao";
const documentoRoute = "/acfaz/documento";

export const assumirEdicaoSolicitacao = (idSolicitacao) =>
  api.post(`${defaultRouteName}/sessao/edicao/${idSolicitacao}/assumir`);

export const realizarUploadFoto = (documento, idSolicitacao) =>
  api.post(`${documentoRoute}/solicitacao`, documento, {
    headers: {
      "content-type": "multipart/form-data",
    },
    params: {
      idSolicitacao,
      tipo: "FOTO",
    },
  });

export const realizarUploadFotoRepresentante = (
  documento,
  idSolicitacao,
  cpfRepresentante,
) =>
  api.post(`${documentoRoute}/solicitacao`, documento, {
    headers: {
      "content-type": "multipart/form-data",
    },
    params: {
      idSolicitacao,
      tipo: "FOTO",
      cpfRepresentante,
    },
  });

export const buscarSolicitacao = async (idSolicitacao) => {
  const response = await api.get(`${defaultRouteName}/${idSolicitacao}`);

  return response.data;
};
