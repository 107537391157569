import { api } from "../api";
const defaultRouteName = "/acfaz/biometria";

export const normalizarFoto = async (
  cpfCliente,
  cpfOperador,
  foto,
  idSolicitacao,
) => {
  const response = await api.post(`${defaultRouteName}/foto/normalizacao`, {
    cpfCliente,
    cpfOperador,
    foto,
    idSolicitacao,
  });

  return response.data;
};
